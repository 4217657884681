@font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/ac1071/00000000000000003b9acafe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/ac1071/00000000000000003b9acafe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/ac1071/00000000000000003b9acafe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
  }
  
  @font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"acumin-pro-wide";
  src:url("https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
  }
  
  @font-face {
  font-family:"acumin-pro-wide";
  src:url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  .tk-acumin-pro { font-family: "acumin-pro",sans-serif; }
  .tk-acumin-pro-wide { font-family: "acumin-pro-wide",sans-serif; }

.App {
  text-align: center;
  font-family: 'acumin-pro'; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.atg-logo {
  height: 5vmin;
  pointer-events: none;
  position: absolute;
  top: 2%;
  left: 2%;
}


/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'San Francisco', Helvetica, Arial, san-serif;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-social-icon .social-svg:hover {
  color: #55acee;
  background: black;

}

.my-social-icon .social-svg {
  color: #BBC7CC;

}

.side {
  display: inline-block;
  margin: 0 10px;
  
}

.walletCheck {
	background-color: transparent;
  color: white;
  border: none;
  overflow-wrap: break-word;
  padding: 20px 20px 20px 20px ;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  align-content: center;
  flex-wrap: wrap;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

#walletInput {
  background-color: #fff;
  color: #000;
  border-radius: 25px;
  height: 40px;
  margin: 12px auto;
  padding: 20px;
  width: 100%;
  max-width: 85vw;
  font-family: "acumin-pro";
  font-size: 1rem;
}

.primary {
  border: none;
  background-color: transparent;
  cursor: pointer;

}

span{
  position: relative;
  display: inline-flex;
  width: 180px;
  height: 55px;
  margin: 0 15px;
  perspective: 1000px;
}
span a{
  font-size: 19px;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform .25s;
  font-family: 'Montserrat', sans-serif;
  
}
span a:before,
span a:after{
  position: absolute;
  content: "CHECK";
  height: 55px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid black;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'acumin-pro-wide';
  font-weight: 800;
}
span a:before{
  color: #fff;
  background: #000;
  transform: rotateY(0deg) translateZ(25px);
}
span a:after{
  color: #000;
  transform: rotateX(90deg) translateZ(25px);
}
span a:hover{
  transform: translateZ(-25px) rotateX(-90deg);
}

.proofDiv {

  width: 100%;
  height: 100%;
  color: black;
  font-size: 1rem;

}